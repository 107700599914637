import "core-js/modules/es.array.push.js";
export default {
  data: function data() {
    return {
      myAuditDetail: {},
      detail_data: {
        phone: "13566666666"
      },
      query: {},
      ucCertificateSubmitVO: {}
    };
  },
  created: function created() {
    this.getDetail();
    this.query = this.$route.query;
  },
  methods: {
    toSubmit: function toSubmit() {
      this.$router.push({
        path: "/user-center/submit-qualification"
      });
    },
    getDetail: function getDetail() {
      var _this = this;
      var url = "/pjyy-deepexi-user-center/api/v1/customer/myAuditDetail";
      this.$getHttpClient().get(url).then(function (res) {
        if (res.data.code == "200") {
          _this.myAuditDetail = res.data.data;
          _this.ucCertificateSubmitVO = res.data.data.ucCertificateSubmitVO;
        }
      });
    }
  }
};